
import { defineComponent,ref } from 'vue'
import httpRequest from '@/service'

export default defineComponent({
  props: {
    backgroundColor: {
      type: String,
      default: "#112032"
    },
    applicationList: {
      type: Array,
      default: () => [
        {
          name: "依法治省（市）",
          path: "GovernByLaw"
        },
        {
          name: "智慧立法",
          path: "WLegislation"
        },
        {
          name: "智慧执法",
          path: "WEnforce"
        },
        {
          name: "智慧监督",
          path: "WSupervision"
        },
        {
          name: "智慧复议",
          path: "WReconsideration"
        },
        {
          name: "公共法律服务",
          path: "LegalServices"
        },
        {
          name: "数据中台",
          path: "DataCenter"
        },
        {
          name: "知识中台",
          path: "KnowledgeCenter"
        }
      ]
    }
  },
  setup() {
    const form = ref({
      tankbidFooter: true
    })

    return {
      form
    }
  }
})
